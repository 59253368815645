@font-face {
  font-family: 'Gentona';
  src: local('Gentona'), url(./assets/font/Gentona.otf) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/font/OpenSans-Regular.ttf) format('woff');
}

html, body {
  height: 100%;
  margin: 0;
  background-color: #F7F7F7;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}
